import env from "#env";
import createApi from "../../../createApi";

import { QUERY_TAGS } from "#constants/query";
import {
  GetUserCustomPropertiesRequest,
  GetUserCustomPropertiesResponse,
  GetUserCustomPropertyValuesRequest,
  GetUserCustomPropertyValuesResponse,
} from "#customTypes/userProperties";
import { appBaseQuery } from "#features/common/baseQuery";

export const userCustomPropertiesApi = createApi({
  reducerPath: "userCustomProperties",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/users-custom-properties/`,
  }),
  tagTypes: [QUERY_TAGS.UserCustomProperties, QUERY_TAGS.UserCustomPropertyValues],
  endpoints: (builder) => ({
    fetchUserCustomProperties: builder.query<
      GetUserCustomPropertiesResponse,
      GetUserCustomPropertiesRequest
    >({
      query: (params) => ({
        url: `types`,
        method: "GET",
        params,
        credentials: "include",
      }),
      providesTags: (_result, _error, { organisationId }) => [
        { type: QUERY_TAGS.UserCustomProperties, id: organisationId },
      ],
    }),
    fetchUserCustomPropertyValues: builder.query<
      GetUserCustomPropertyValuesResponse,
      GetUserCustomPropertyValuesRequest
    >({
      query: (params) => ({
        url: `values`,
        method: "GET",
        params,
        credentials: "include",
      }),
      providesTags: (_result, _error, { userCustomPropertyTypeId }) => [
        { type: QUERY_TAGS.UserCustomPropertyValues, id: userCustomPropertyTypeId },
      ],
    }),
  }),
});

export const {
  useFetchUserCustomPropertiesQuery,
  useFetchUserCustomPropertyValuesQuery,
} = userCustomPropertiesApi;
